import React from 'react';

const Footer = () => {
  return (
    <footer className='footer text-center'>
      Made with ❤ by <a href='http://devanshdalal.github.io'>devanshdalal</a>.
    </footer>
  );
};
export default Footer;
